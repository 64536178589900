import "@css/app.css";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import Alpine from "alpinejs";
import "slick-carousel";
import $ from "jquery";
import jQueryBridget from 'jquery-bridget';
import Isotope from 'isotope-layout';

import { gsap } from "gsap";

window.Swiper = Swiper
window.Alpine = Alpine
Alpine.start();

if ($('.banner-items')) {
    $(".banner-items").slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: true,
        dots: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 790,
                settings: {
                    dots: false
                }
            }
        ]
    });
}

window.shareArticle = (element, e) => {
    e.preventDefault();
    const url = element.href;
    const leftPos = window.innerWidth / 2 - (631 / 2);
    const topPos = window.innerHeight / 2 - (446 / 2);
    const windowFeatures = `left=${leftPos},top=${topPos},width=631,height=446`;
    window.open(url, "newwindow", windowFeatures);
    return false;
}

window.log = () => {
    console.log('Log');
}

//  filters

if ($('#jsform')) {
  var filters = {};

  Isotope.Item.prototype._create = function() {
    // assign id, used for original-order sorting
    this.id = this.layout.itemGUID++;
    // transition objects
    this._transn = {
      ingProperties: {},
      clean: {},
      onEnd: {}
    };
    this.sortData = {};
  };

  Isotope.Item.prototype.layoutPosition = function() {
    this.emitEvent( 'layout', [ this ] );
  };

  Isotope.LayoutMode.create('none');

  jQueryBridget( 'isotope', Isotope, $ );

  var $checkboxes = $('.sidebar-menu input');
  var qsRegex;
  var buttonFilter;
  var selector = '*';
  var itemSelector = '.section'; 

  $(document).ready(function() {
    
    function debounce( fn, threshold ) {
      var timeout;
      return function debounced() {
      if ( timeout ) {
        clearTimeout( timeout );
      }
      function delayed() {
        fn();
        timeout = null;
      }
      timeout = setTimeout( delayed, threshold || 100 );
      };
    }

    var $container = $('#listing');
    $container.isotope({
      itemSelector: itemSelector,
      layoutMode: 'none',
      containerStyle: null,
      transitionDuration: 0,
      filter: '*'
    });

    var $quicksearch = $('.search').keyup(debounce(function() {

      qsRegex = $quicksearch.val().length > 0 ? new RegExp($quicksearch.val(), 'gi') : null;
      var filterFns = {
        kSearch: function (itemElem) {
          return qsRegex ? itemElem.textContent.match(qsRegex) : true;
        }
      };
      var filterValue = selector;

      filterValue = qsRegex ? filterFns['kSearch'] || filterValue : filterValue;
      $container.isotope({filter: filterValue});
      loadMore(initShow);
      
    }, 200));

    $('#options').on( 'change', function( event ) {
      var checkbox = event.target;
      var $checkbox = $( checkbox );
      var group = $checkbox.parents('.option-set').attr('data-group');
      // create array for filter group, if not there yet
      var filterGroup = filters[ group ];
      if ( !filterGroup ) {
        filterGroup = filters[ group ] = [];
      }
      // add/remove filter
      if ( checkbox.checked ) {
        filterGroup.push( checkbox.value );
        $checkbox.attr('checked', true);
      } else {
        var index = filterGroup.indexOf( checkbox.value );
        filterGroup.splice( index, 1 );
        $checkbox.attr('checked', false);
      }

      var filterValue = getComboFilter();
      $container.isotope({ filter: filterValue });
      loadMore(initShow);
      $quicksearch.val('');
    });

    function getComboFilter() {
      var combo = [];
      for ( var prop in filters ) {
        var group = filters[ prop ];
        if ( !group.length ) {
          // no filters in group, carry on
          continue;
        }
        // add first group
        if ( !combo.length ) {
          combo = group.slice(0);
          continue;
        }
        // add additional groups
        var nextCombo = [];
        // split group into combo: [ A, B ] & [ 1, 2 ] => [ A1, A2, B1, B2 ]
        for ( var i=0; i < combo.length; i++ ) {
          for ( var j=0; j < group.length; j++ ) {
            var item = combo[i] + group[j];
            nextCombo.push( item );
          }
        }
        combo = nextCombo;
      }
      var comboFilter = combo.join(', ');
      return comboFilter;
    }

    var initShow = 9; 
    var counter = initShow; 
    var iso = $container.data('isotope');

    loadMore(initShow); 

    function loadMore(toShow) {
      $container.find(".hidden").removeClass("hidden");

      if (iso) {
        var hiddenElems = iso.filteredItems.slice(toShow, iso.filteredItems.length).map(function(item) {
          return item.element;
        });

        $(hiddenElems).addClass('hidden');
        $container.isotope('layout');

        if (hiddenElems.length == 0) {
          $("#load-more").hide();
        } else {
          $("#load-more").show();
        };
      }

    }

    $container.after('<div class="w-full text-center"><button id="load-more" type="button" class="button-read-more my-4 md:mb-0 md:mr-4">Load More</button></div>');


    $("#load-more").click(function() {
      if ($('#options').data('clicked')) {
        counter = initShow;
        $('#options').data('clicked', false);
      } else {
        counter = counter;
      };
      counter = counter + initShow;
      loadMore(counter);
    });

  });

}

if ($('#resource-back-top')) {
    $(window).scroll(function() {
        var pos  = $(window).scrollTop();

        if(pos >= 900) {
            $('#resource-back-top').addClass('visible');
            $('#resource-back-top').removeClass('invisible')
        } else {
            $('#resource-back-top').addClass('invisible');
            $('#resource-back-top').removeClass('visible')
        }

        $('#resource-back-top').on('click', function(){
            $('html, body').stop().animate({
                scrollTop: 0
            }, 500);
        });
    
    });
}

if ($('#jsform')) {
  $('.tabs button').click(function () {
    $('.tabs button').toggleClass('text-turquoise')
    $('.faq-content').toggleClass('current');
    $('.tabs--resources').toggleClass('hidden');
    $("#load-more").toggleClass('hidden');
    $("#search-block").toggleClass('hidden');
  });
}
